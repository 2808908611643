import { updateExperiment } from "../../../api/experimentManagement";
import refreshExperiments from "./refreshExperiments";
// editExperiment(
//   accessToken,
//   "title",
//   title || experiment.experiment_title,
//   description || experiment.experiment_description,
//   experiment.experiment_id,
//   setError,
//   setMessage,
//   setLoading,
//   setTitle,
//   setDescription,
//   setIsEditingTitle,
//   setIsEditingDescription,
//   setExperiments
// );
export default async function editExperiment(
  accessToken,
  field,
  title,
  description,
  experimentId,
  setError,
  setMessage,
  setLoading,
  setExperiments
) {
  try {
    if (!accessToken) throw new Error("Unable to retrieve access token.");
    if (!field) throw new Error("Missing field argument");
    if (field !== "title" && field !== "description") {
      throw new Error(`Invalid editable field argument: ${field}`);
    }
    if (field === "title" && !title) {
      throw new Error("No title changes detected");
    }
    if (field === "description" && !description) {
      throw new Error("No description changes detected");
    }

    setError("");
    setMessage("");
    setLoading(true);

    await updateExperiment(accessToken, experimentId, title, description);

    setLoading(false);
    setMessage("Experiment successfully updated.");

    await refreshExperiments(accessToken, setExperiments, setError, setLoading);
    // const newestData = await getExperimentInfo(experiment.experiment_id);
    // Need to set experiment with the new info
  } catch (err) {
    setLoading(false);
    console.error("Error saving experiment edits: " + err.message);
    setError("Error saving experiment: " + err.message);
  }
}
