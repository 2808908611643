"use client";
import { useEffect, useRef, useState } from "react";
import {
  Alert,
  Box,
  IconButton,
  Dialog,
  DialogContent,
  DialogTitle,
  Pagination,
  Typography,
  OutlinedInput,
  InputAdornment,
} from "@mui/material";
import {
  Cancel as CancelIcon,
  Search as SearchIcon,
} from "@mui/icons-material";
import ReviveBrainCard from "./ReviveBrainCard";
import { getFrozenBrains } from "../api/experimentManagement";
import { reviveBrain } from "../api/feagiSessionManagement";
import { logger } from "../util/logger";

const ReviveBrain = ({
  accessToken,
  experiment,
  reviveOpen,
  setReviveOpen,
  setError,
  setMessage,
  clearAlerts,
}) => {
  const [brains, setBrains] = useState([]);
  const [dialogMessage, setDialogMessage] = useState(""); // Show a message inside the dialog
  const [dialogError, setDialogError] = useState(""); // Show a message inside the dialog
  // Search
  const searchInputRef = useRef();
  // Pagination
  const [page, setPage] = useState(1); // Current page number
  const limit = 4; // Items per page
  const [totalCount, setTotalCount] = useState(null);

  logger("experiment:", experiment);

  const clearAllAlerts = () => {
    clearAlerts();
    setDialogMessage("");
    setDialogError("");
  };

  // Refetch data on search submit
  const handleSearchSubmit = () => {
    fetchBrains();
  };

  // const dummyData = [
  //   {
  //     id: "123",
  //     timestamp: "2022-12-17",
  //     user_note: "Robot is moving around but responds oddly on left direction",
  //     neuron_count: 1342,
  //     synapse_count: 129,
  //   },
  //   {
  //     id: "1234",
  //     timestamp: "2022-12-15",
  //     user_note: "some random thing",
  //     neuron_count: 1220,
  //     synapse_count: 129,
  //   },
  // ];

  async function fetchBrains() {
    const skip = (page - 1) * limit;
    const res = await getFrozenBrains(
      accessToken,
      experiment.experiment_id,
      skip,
      limit,
      searchInputRef.current?.value
    );
    const data = res.data;
    setBrains(data);
    setTotalCount(data.length);
    // const data = skip < 1 ? dummyData.slice(0, 4) : dummyData.slice(4);
    // setBrains(data);
    // setTotalCount(dummyData.length);
  }

  // Grab initial data & update on page change
  useEffect(() => {
    experiment && fetchBrains();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [experiment, page]);

  // Handle user selection of a brain state
  const handleSelect = async (connectomeId, freezeRunning) => {
    try {
      clearAlerts();
      logger(
        "experimentId:",
        experiment.experiment_id,
        "connectomeId:",
        connectomeId
      );
      await reviveBrain(
        accessToken,
        experiment.experiment_id,
        connectomeId,
        freezeRunning
      );
      setMessage(
        `Brain revival process for "${experiment?.experiment_title}" is complete. This experiment will now default to the chosen brain state.`
      );
    } catch (err) {
      console.error(err);
      setError(err.message);
    }
    setReviveOpen(false);
  };

  return (
    <Dialog
      open={reviveOpen}
      onClose={() => setReviveOpen(false)}
      aria-labelledby="revive-title"
      aria-describedby="revive-brain"
      sx={{ minWidth: "600px" }}
    >
      <DialogTitle id="revive-title" sx={{ paddingBottom: "5px" }}>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          Revive Brain
          <IconButton size="small" onClick={() => setReviveOpen(false)}>
            <CancelIcon />
          </IconButton>
        </Box>
        <Box
          component="hr"
          sx={{ borderColor: "accents.main", width: "100%" }}
        />
      </DialogTitle>
      <DialogContent
        sx={{
          minWidth: "450px",
          // paddingTop: "10px !important",
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-end",
          gap: "15px",
        }}
      >
        <Box display="flex" flexDirection="column" gap="15px" width="100%">
          <Box display="flex" flexDirection="column" gap="15px">
            <Typography>
              Select a frozen brain state to revive for{" "}
              <Typography variant="span" fontWeight="bold">
                {experiment?.experiment_title || "your experiment"}
              </Typography>
              .{" "}
            </Typography>
            {dialogError && <Alert severity="error">{dialogError}</Alert>}
            {dialogMessage && <Alert severity="success">{dialogMessage}</Alert>}
          </Box>
          {/* Search field */}
          <Box>
            <OutlinedInput
              inputRef={searchInputRef}
              endAdornment={
                <InputAdornment position="end">
                  <SearchIcon
                    sx={{ color: "white", cursor: "pointer" }}
                    onClick={handleSearchSubmit}
                  />
                </InputAdornment>
              }
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  handleSearchSubmit();
                }
              }}
              sx={{
                height: "40px",
                width: "100%",
                color: "white",
                borderRadius: "4px",
                ".MuiOutlinedInput-notchedOutline": {
                  borderColor: "rgba(255, 255, 255, 0.23)", // Default border color
                },
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  borderColor: "inherit", // Border color on hover
                },
                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                  borderColor: "primary.main", // Border color when focused
                },
              }}
            />
          </Box>
          <Box display="flex" flexDirection="column" gap="10px">
            {brains.length > 0 ? (
              brains.map((brain, index) => (
                <ReviveBrainCard
                  key={index}
                  accessToken={accessToken}
                  brain={brain}
                  experiment={experiment}
                  handleSelect={handleSelect}
                  clearAllAlerts={clearAllAlerts}
                  setDialogError={setDialogError}
                  setDialogMessage={setDialogMessage}
                />
              ))
            ) : (
              <Typography fontStyle="italic">
                There are no frozen brains for this experiment.
              </Typography>
            )}
          </Box>
        </Box>
        <Pagination
          count={Math.ceil(totalCount / limit)}
          page={page}
          onChange={(event, value) => setPage(value)}
          sx={{
            justifyContent: "center",
            marginTop: "20px",
            width: "100%",
            "& ul": {
              // target the ul element inside the Pagination
              justifyContent: "center",
              marginTop: "20px",
            },
          }}
        />
      </DialogContent>
    </Dialog>
  );
};

export default ReviveBrain;
