import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material";
import { Cancel as CancelIcon } from "@mui/icons-material";

const ExperimentStopDialog = ({
  stopDialogOpen,
  setStopDialogOpen,
  handleStop,
}) => {
  const handleStopDialogClose = () => {
    setStopDialogOpen(false);
  };

  return (
    <Dialog
      open={stopDialogOpen}
      onClose={handleStopDialogClose}
      aria-labelledby="seed-change-title"
    >
      <DialogTitle id="seed-change-title">
        {"Stop Experiment?"}
        <IconButton
          style={{ position: "absolute", right: 7, top: 7, padding: 3 }}
          onClick={handleStopDialogClose}
          size="small"
        >
          <CancelIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Typography>
          Stop your current experiment and return to the lab?
        </Typography>
        <Typography>
          {/* (Your genome progress is autosaved. If you wish to save the current
        brain state, <span style={{ fontWeight: "600" }}>freeze</span> it
        from the navbar.) */}
          (Your genome progress is autosaved, but anything the brain has learned
          using it will be lost.)
        </Typography>
      </DialogContent>
      <DialogActions
        sx={{
          padding: "8px 24px 16px 24px",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Button
          variant="contained"
          onClick={handleStopDialogClose}
          color="accents"
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={(event) => handleStop(event)}
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ExperimentStopDialog;
