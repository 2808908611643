import { apiRequestExternal } from "./apiRequestExternal";
import { apiValidateArguments } from "./apiValidateArguments";
import { apiFilterNonNull } from "./apiFilterNonNull";
import { logger } from "../util/logger";

// get info about an experiment ("Experiment Details")
export async function getExperimentInfo(experimentId, accessToken) {
  try {
    // Validate args
    apiValidateArguments({ experimentId, accessToken });

    // Return custom response data
    const response = await apiRequestExternal(
      `${process.env.NEXT_PUBLIC_COMPOSER_URL}/v1/public/regional/auth/user/experiments/experiment?experiment_id=${experimentId}`,
      "GET",
      null,
      { Authorization: accessToken }
    );

    logger("Response in getExperimentInfo function:", response);
    return response;

    // Handle errors
  } catch (error) {
    console.error("Error in getExperimentInfo function:", error);
    throw error;
  }
}

// get all open experiment sessions ("Experiment Open Sessions")
export async function getExperimentSessions(experimentId, accessToken) {
  try {
    // Validate args
    apiValidateArguments({ experimentId, accessToken });

    // Return custom response data
    const response = await apiRequestExternal(
      `${process.env.NEXT_PUBLIC_COMPOSER_URL}/v1/public/regional/auth/user/experiments/sessions?experiment_id=${experimentId}`,
      "GET",
      null,
      { Authorization: accessToken }
    );

    logger("Response in getExperimentSessions function:", response);
    return response;

    // Handle errors
  } catch (error) {
    console.error("Error in getExperimentSessions function:", error);
    throw error;
  }
}

// create an experiment
export async function createExperiment(
  accessToken,
  title,
  environmentId,
  embodimentId,
  genomeId,
  params = {}, // choosing older version of FEAGI, gazebo, etc.
  description = ""
) {
  try {
    // Validate args
    apiValidateArguments({
      accessToken,
      title,
      environmentId,
      embodimentId,
      genomeId,
    });

    // Filter to only pass provided args in request body
    const body = apiFilterNonNull({
      title: title,
      environment_id: environmentId,
      embodiment_id: embodimentId,
      seed_genome_id: genomeId,
      params: params,
      description: description,
    });

    // Return custom response data
    const response = await apiRequestExternal(
      `${process.env.NEXT_PUBLIC_COMPOSER_URL}/v1/public/regional/auth/user/experiments/experiment`,
      "POST",
      body,
      { Authorization: accessToken }
    );

    logger("Response in createExperiment function:", response);
    return response;

    // Handle errors
  } catch (error) {
    console.error("Error in createExperiment function:", error);
    throw error;
  }
}

// update / edit an experiment ("Update Experiment")
export async function updateExperiment(
  accessToken,
  experimentId,
  title,
  description,
  seedGenomeId,
  params = {}
) {
  try {
    // Validate args
    apiValidateArguments({ accessToken, experimentId });
    // if (!title) throw new Error("Need title even if unchanged.");

    // Filter to only pass provided args in request body
    const body = apiFilterNonNull({
      experiment_id: experimentId,
      title: title,
      description: description,
      seed_genome_id: seedGenomeId,
      params: params,
    });

    // Return custom response data
    const response = await apiRequestExternal(
      `${process.env.NEXT_PUBLIC_COMPOSER_URL}/v1/public/regional/auth/user/experiments/experiment/update`,
      "PUT",
      body,
      { Authorization: accessToken }
    );

    logger("Response in updateExperiment function:", response);
    return response;

    // Handle errors
  } catch (error) {
    console.error("Error in updateExperiment function:", error);
    throw error;
  }
}

// run/launch/start a experiment ("Start Experiment")
export async function launchExperiment(accessToken, experimentId) {
  try {
    // Validate args
    apiValidateArguments({ accessToken, experimentId });

    // Return custom response data
    const response = await apiRequestExternal(
      `${process.env.NEXT_PUBLIC_COMPOSER_URL}/v1/public/regional/auth/user/experiments/experiment/start`,
      "POST",
      { experiment_id: experimentId },
      { Authorization: accessToken }
    );

    logger("Response in launchExperiment function:", response);
    return response;

    // Handle errors
  } catch (error) {
    console.error("Error in launchExperiment function:", error);
    throw error;
  }
}

// stop an experiment ("Stop Experiment")
export async function stopExperiment(accessToken, experimentId) {
  try {
    // Validate args
    apiValidateArguments({ accessToken, experimentId });

    // Return custom response data
    const response = await apiRequestExternal(
      `${process.env.NEXT_PUBLIC_COMPOSER_URL}/v1/public/regional/auth/user/experiments/experiment/stop`,
      "POST",
      { experiment_id: experimentId },
      { Authorization: accessToken }
    );

    logger("Response in stopExperiment function:", response);
    return response;

    // Handle errors
  } catch (error) {
    console.error("Error in stopExperiment function:", error);
    throw error;
  }
}

// delete an experiment ("Delete Experiment")
export async function deleteExperiment(accessToken, experimentId) {
  try {
    // Validate args
    apiValidateArguments({ accessToken, experimentId });

    // Return custom response data
    const response = await apiRequestExternal(
      `${process.env.NEXT_PUBLIC_COMPOSER_URL}/v1/public/regional/auth/user/experiments/experiment/delete`,
      "DELETE",
      { experiment_id: experimentId },
      { Authorization: accessToken }
    );

    logger("Response in deleteExperiment function:", response);
    return response;

    // Handle errors
  } catch (error) {
    console.error("Error in deleteExperiment function:", error);
    throw error;
  }
}

// Get IP to let user connect a non-microbit robot
export async function getInstanceIp(sessionId, accessToken) {
  try {
    // Validate args
    apiValidateArguments({ accessToken, sessionId });

    // Return custom response data
    const response = await apiRequestExternal(
      `${process.env.NEXT_PUBLIC_COMPOSER_URL}/v1/public/regional/auth/user/experiments/properties/session/ip?session_id=${sessionId}`,
      "GET",
      null,
      { Authorization: accessToken }
    );

    logger("Response in getInstanceIp function:", response);
    return response;

    // Handle errors
  } catch (error) {
    console.error("Error in getInstanceIp function:", error);
    throw error;
  }
}

// Get list of user's frozen brain states (connectomes)
export async function getFrozenBrains(accessToken, experimentId) {
  try {
    // Validate args
    apiValidateArguments({ accessToken, experimentId });

    // Return custom response data
    const response = await apiRequestExternal(
      `${process.env.NEXT_PUBLIC_COMPOSER_URL}/v1/public/regional/auth/user/experiments/connectomes?experiment_id=${experimentId}`,
      "GET",
      null,
      { Authorization: accessToken }
    );

    logger("Response in getFrozenBrains function:", response);
    return response;

    // Handle errors
  } catch (error) {
    console.error("Error in getFrozenBrains function:", error);
    throw error;
  }
}

// Edit a frozen brain
export async function editFrozenBrain(accessToken, connectomeId, freezeNote) {
  try {
    // Validate args
    apiValidateArguments({ accessToken, connectomeId, freezeNote });

    // Return custom response data
    const response = await apiRequestExternal(
      `${process.env.NEXT_PUBLIC_COMPOSER_URL}/v1/public/regional/auth/user/experiments/connectome`,
      "PUT",
      {
        connectome_id: connectomeId,
        freeze_note: freezeNote,
      },
      { Authorization: accessToken }
    );

    logger("Response in editFrozenBrain function:", response);
    return response;

    // Handle errors
  } catch (error) {
    console.error("Error in editFrozenBrain function:", error);
    throw error;
  }
}

// Delete a frozen brain
export async function deleteFrozenBrain(accessToken, connectomeId) {
  try {
    // Validate args
    apiValidateArguments({ accessToken, connectomeId });

    // Return custom response data
    const response = await apiRequestExternal(
      `${process.env.NEXT_PUBLIC_COMPOSER_URL}/v1/public/regional/auth/user/experiments/connectome?connectome_id=${connectomeId}`,
      "DELETE",
      null,
      { Authorization: accessToken }
    );

    logger("Response in deleteFrozenBrain function:", response);
    return response;

    // Handle errors
  } catch (error) {
    console.error("Error in deleteFrozenBrain function:", error);
    throw error;
  }
}

// toggle an experiment as favorite/non-favorite
export async function toggleExperimentFavorite(accessToken, experimentId) {
  try {
    // Validate args
    apiValidateArguments({ accessToken, experimentId });

    // Return custom response data
    const response = await apiRequestExternal(
      `${process.env.NEXT_PUBLIC_COMPOSER_URL}/v1/public/regional/auth/user/experiments/toggle_favorite`,
      "PUT",
      { experiment_id: experimentId },
      { Authorization: accessToken }
    );

    logger("Response in toggleExperimentFavorite function:", response);
    return response;

    // Handle errors
  } catch (error) {
    console.error("Error in toggleExperimentFavorite function:", error);
    throw error;
  }
}

// Check if server is at capacity
export async function isSystemOverloaded(
  token = process.env.NEXT_PUBLIC_PROJECT_ID
) {
  try {
    // Validate args
    apiValidateArguments({ token });

    // Return custom response data
    const response = await apiRequestExternal(
      `${process.env.NEXT_PUBLIC_COMPOSER_URL}/v1/public/regional/auth/user/experiments/system_overload`,
      "GET",
      null,
      { Authorization: token }
    );

    logger("Response in isSystemOverloaded function:", response);
    return response;

    // Handle errors
  } catch (error) {
    console.error("Error in isSystemOverloaded function:", error);
    throw error;
  }
}

// Example response data:
// {
//   "system_overload": false,
//   "max_experiment_count_reached": false,
//   "max_concurrent_experiments_reached": false
// }
// Check if a new experiment can be launched
export async function getLaunchCriteria(accessToken) {
  try {
    // Validate args
    apiValidateArguments({ accessToken });

    // Return custom response data
    const response = await apiRequestExternal(
      `${process.env.NEXT_PUBLIC_COMPOSER_URL}/v1/public/regional/auth/user/experiments/launch_criteria`,
      "GET",
      null,
      { Authorization: accessToken }
    );

    logger("Response in getLaunchCriteria function:", response);
    return response;

    // Handle errors
  } catch (error) {
    console.error("Error in getLaunchCriteria function:", error);
    throw error;
  }
}

// Replace embodiment for an experiment
export async function replaceEmbodiment(
  accessToken,
  sessionId,
  experimentId,
  embodimentId,
  embodimentTitle
) {
  try {
    // Validate args
    apiValidateArguments({
      accessToken,
      sessionId,
      experimentId,
      embodimentId,
      embodimentTitle,
    });

    // Return custom response data
    const response = await apiRequestExternal(
      `${process.env.NEXT_PUBLIC_COMPOSER_URL}/v1/public/regional/auth/user/experiments/replace_embodiment`,
      "POST",
      {
        session_id: sessionId,
        experiment_id: experimentId,
        embodiment_id: embodimentId,
        embodiment_title: embodimentTitle,
      },
      { Authorization: accessToken }
    );

    logger("Response in replaceEmbodiment function:", response);
    return response;

    // Handle errors
  } catch (error) {
    console.error("Error in replaceEmbodiment function:", error);
    throw error;
  }
}

// Get FEAGI & connector versions
export async function getAllVersions(accessToken, experimentId) {
  try {
    // Validate args
    apiValidateArguments({ accessToken });

    // Return custom response data
    const response = await apiRequestExternal(
      `${process.env.NEXT_PUBLIC_COMPOSER_URL}/v1/public/regional/auth/user/experiments/feagi_versions?experiment_id=${experimentId}`,
      "GET",
      null,
      { Authorization: accessToken }
    );

    logger("Response in getAllVersions function:", response);
    return response;

    // Handle errors
  } catch (error) {
    console.error("Error in getAllVersions function:", error);
    throw error;
  }
}

// Change FEAGI version
export async function changeVersion(accessToken, experimentId, feagiVersionId) {
  try {
    // Validate args
    apiValidateArguments({
      accessToken,
      experimentId,
      feagiVersionId,
    });

    // Return custom response data
    const response = await apiRequestExternal(
      `${process.env.NEXT_PUBLIC_COMPOSER_URL}/v1/public/regional/auth/user/experiments/experiment_feagi_version`,
      "PUT",
      {
        experiment_id: experimentId,
        feagi_img_version: feagiVersionId,
      },
      { Authorization: accessToken }
    );

    logger("Response in changeVersion function:", response);
    return response;

    // Handle errors
  } catch (error) {
    console.error("Error in changeVersion function:", error);
    throw error;
  }
}
