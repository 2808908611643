"use client";
import { forwardRef } from "react";
import { Box, Button, Card, CardContent, Tooltip } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";

const StandardCard = forwardRef(
  (
    {
      key,
      TopLeft,
      TopRight,
      BottomLeft,
      BottomRight,
      showMore,
      setShowMore,
      rightColumnWidth = "30%",
      leftColumnWidth = "70%",
      cardStyle,
    },
    ref
  ) => {
    const ShowMoreButtons = () => {
      return !showMore ? (
        <Box display="flex" justifyContent="flex-end">
          <Tooltip title="Show more details">
            <Button
              variant="text"
              sx={{
                minWidth: 0,
                padding: 0,
                fontSize: "1rem",
                textTransform: "none",
                color: "accents.main",
              }}
              onClick={() => setShowMore((prev) => !prev)}
            >
              <ExpandMoreIcon />
            </Button>
          </Tooltip>
        </Box>
      ) : (
        <Box display="flex" justifyContent="end">
          <Tooltip title="See less details">
            <Button
              sx={{
                minWidth: 0,
                padding: 0,
                fontSize: "1rem",
                textTransform: "none",
                color: "accents.main",
              }}
              onClick={() => setShowMore((prev) => !prev)}
            >
              <ExpandLessIcon />
            </Button>
          </Tooltip>
        </Box>
      );
    };

    return (
      <Card
        key={key}
        sx={{ width: "100%", ...cardStyle }}
        ref={ref ? ref : undefined}
      >
        <CardContent
          sx={{
            width: "100%",
            pt: "16px",
            pb: "16px !important",
            display: "flex",
            justifyContent: "space-between",
            gap: "10px",
          }}
        >
          {/* Left Column */}
          <Box width={leftColumnWidth} display="flex" flexDirection="column">
            {TopLeft ? <TopLeft /> : null}
            {BottomLeft ? <BottomLeft /> : null}
          </Box>

          {/* Right Column */}
          <Box
            width={rightColumnWidth}
            display="flex"
            flexDirection="column"
            alignItems="flex-end"
            justifyContent="space-between"
          >
            {TopRight ? <TopRight /> : null}
            {BottomRight ? <BottomRight /> : <ShowMoreButtons />}
          </Box>
        </CardContent>
      </Card>
    );
  }
);

StandardCard.displayName = "StandardCard";

export default StandardCard;
